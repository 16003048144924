import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

const query = graphql`
  {
    allStrapiFaqCategories {
      nodes {
        FaqCategoryName
        Questions {
          Answer
          Question
        }
      }
    }
  }
`;

const FaqComponent = () => {
  const data = useStaticQuery(query);
  const {
    allStrapiFaqCategories: { nodes: faqs },
  } = data;
  return (
    <div className="faq-accordion">
      {faqs.map((faq, idx) => {
        return (
          <div className="faq-category" key={idx}>
            <h2 className="faq-category-title">{faq.FaqCategoryName}</h2>
            <Accordion>
              {faq.Questions.map((ques, idx2) => {
                return (
                  <AccordionItem key={idx2}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{ques.Question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="faq-category-question">
                        <ReactMarkdown>{ques.Answer}</ReactMarkdown>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default FaqComponent;
