import React from "react";
import Footer from "../../components/App/Footer";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import FaqComponent from "../../components/Resources/FaqComponent";

const FAQ = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <section className="faq-section bg-gray">
          <div className="container">
            <div className="section-title">
              <h2>FAQ</h2>
              <p>Have any questions?</p>
            </div>
          </div>
        </section>
        <section className="faq-component-section">
          <div className="container">
            <FaqComponent />
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  );
};

export default FAQ;
